.defaultSelect {
    min-width: 10rem !important;
}

.centeredThrobber {
    flex-grow: 1;
    height: 100%;
}

.userCard {
    @include _border;
    height: fit-content;
    padding: 0.61rem;
    width: fit-content;
}

.bg-red {
    background-color: #ffdabc;
}

.completed {
    opacity: 0.5;
}

.marquee {
    margin: 5.5rem 1.5rem 1rem 1.5rem;
    overflow: hidden;
    font-weight: 300;
    font-size: 24px;
}
  
.marqueeInner {
    display: flex;
}

.marqueeEmpty, .marqueeLine {
    flex-shrink: 0;
    margin: 0;
    padding: 10px 15px;
    min-width: 100%;
}

.marqueeEmpty {
    text-align: center;
}

.marqueeLine {
    white-space: nowrap;
    animation-name: marqueeLineAnimation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    & img {
        margin: 0 8px;
        height: 24px;
    }
}
  
@keyframes marqueeLineAnimation {
    from {
      transform: translateX(0%);
    }
    
    to {
      transform: translateX(-100%);
    }
}