.toolBarWrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    margin-bottom: 1rem;
    @include _border;
}

.editorBox {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid $grayColor;
    outline: none;
}
