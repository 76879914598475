.vh100 {
    height: 100vh;
}

.w100 {
    width: 100%;
}

.tac {
    text-align: center;
}

.mb1rem {
    margin-bottom: 1rem;
}

.db {
    display: block;
}

.bp1rem {
    padding-bottom: 1rem;
}

.logo {
    background-image: url('/assets/logo.svg');
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: $mainColor;
    border-radius: 6px;

    &.large {
        width: 150px;
        height: 150px;
    }
}
